import request from '@/utils/request'

export function queryOrderList (data) {
  return request({
    url: '/pay/orderList',
    data
  })
}

export function queryPayList (data) {
  return request({
    url: '/pay/payList',
    data
  })
}

export function queryNationList (data) {
  return request({
    url: '/pay/nationList',
    data
  })
}

export function updatePayList (data) {
  return request({
    url: '/pay/update/payList',
    data
  })
}
