<template>
  <Layout
    v-loading="loading"
    class="pay"
  >
    <el-table
      :data="dataList"
      border
    >
      <el-table-column
        prop="name"
        label="支付方式"
        width="120"
      />
      <el-table-column
        prop="icons"
        label="图标"
        width="190"
      >
        <template v-slot="{row}">
          <div>
            <span
              v-for="(item, index) in row.iconsArr"
              :key="index"
            >
              <el-avatar
                :size="45"
                :src="item"
              />
            </span>
            <i
              class="el-icon-circle-plus-outline"
              style="font-size: 30px"
              @click="handleEditIcons(row)"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="nations"
        label="地区选择"
      >
        <template v-slot="{row}">
          <div :class="nationsClass[row.id]">
            <span
              v-for="(item, index) in row.nationsZh"
              :key="index"
            >
              {{ item }}
            </span>
          </div>
          <div style="display: flex; float: right; width: 115px">
            <el-link
              v-if="nationsClass[row.id]=='wrap'"
              type="primary"
              :underline="false"
              @click="$set(nationsClass,row.id, '')"
            >
              展开
            </el-link>
            <el-link
              v-if="nationsClass[row.id]==''"
              type="primary"
              :underline="false"
              @click="$set(nationsClass,row.id, 'wrap')"
            >
              收起
            </el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="handleEditNations(row)"
            >
              编辑地区
            </el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        width="160"
        label="控制"
      >
        <template v-slot="{row}">
          <el-radio
            v-model="row.type"
            :label="1"
          >
            选择
          </el-radio>
          <el-radio
            v-model="row.type"
            :label="2"
          >
            排除
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column
        prop="version"
        label="版本"
        width="100"
      >
        <template v-slot="{row}">
          <el-tag type="success">
            {{ row.version }}
            <el-popover
              placement="top"
              width="100"
              trigger="click"
            >
              <el-input
                v-model="row.version"
                placeholder="请输入版本"
              />
              <i
                slot="reference"
                class="el-icon-edit"
              />
            </el-popover>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        width="80"
        label="操作"
      >
        <template v-slot="{row}">
          <BTextButton
            title="删除"
            icon="el-icon-delete"
            @click="handleDel(row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="对话框"
      :visible.sync="dialogIcons"
      width="300px"
    >
      <el-form ref="form">
        <el-form-item label="配置图标">
          <br>
          <div style="display: flex; justify-content: space-between; width: 190px;">
            <div
              v-for="(item, index) in formIcons.icons"
              :key="index"
              style="flex-flow:row-reverse wrap; border: 1px solid #e6e6e6; width: 50px; height: 50px;"
            >
              <div>
                <el-image
                  style="width: 50px; height: 50px"
                  :src="item"
                />
                <i
                  class="el-icon-error"
                  style="color:red; font-size: 14px; position: absolute; margin-left: -8px; margin-top: -6px; z-index: 999"
                  @click="handleRemove(item)"
                />
              </div>
            </div>
            <el-upload
              v-if="formIcons.icons && formIcons.icons.length < 3"
              :action="UPLOAD_URL"
              accept="image/jpeg,image/jpg,image/png"
              :multiple="false"
              :on-success="handleSuccess"
              :show-file-list="false"
            >
              <div style="border: 1px dashed #d9d9d9; width: 50px; height: 50px; display: inline-block;">
                <i
                  class="el-icon-plus"
                  style="font-size: 50px"
                />
              </div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogIcons=false">
            取消
          </el-button>
          <el-button
            type="primary"
            style="margin-left: 50px"
            @click="handleComplete(formIcons)"
          >
            完成
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="对话框"
      :visible.sync="dialogNations"
      width="450px"
    >
      <el-form ref="form">
        <el-form-item>
          <el-select
            v-model="formData.countryCodeList"
            style="width: 400px"
            multiple
            clearable
            filterable
            :filter-method="filterMethod"
            collapse-tags
            value-key="countryCode"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('countryCodeList', nationsCode, formData)"
              >
                {{ selectButtonLabel(formData.countryCodeList, nationsCode) }}
              </div>
            </div>
            <el-option
              v-for="(item, index) in filterNationList"
              :key="index"
              :label="item.nationsZh"
              :value="item.nationsCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <div style="width: 190px; float: right">
            <el-button @click="dialogNations=false">
              取消
            </el-button>
            <el-button
              type="primary"
              style="margin-left: 40px"
              @click="handleNationsComplete(formNations)"
            >
              完成
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-button
      type="primary"
      style="margin: 30px auto; display: block; width: 100px "
      @click="handleConfirm"
    >
      确认
    </el-button>
  </Layout>
</template>

<script>
import {
  queryNationList, queryPayList, updatePayList
} from '@/api/trade-management.js'

export default {
  name: 'Pay',
  data () {
    return {
      UPLOAD_URL: process.env.VUE_APP_BASEURL + process.env.VUE_APP_UPLOAD_URL,
      loading: false,
      nationsClass: [],
      dialogIcons: false,
      dialogNations: false,
      isVertical: true,
      formIcons: {},
      formNations: {},
      dataList: [],
      formData: {
        countryCodeList: []
      },
      nationList: [],
      filterNationList: [],
      nationsCode: []
    }
  },
  created () {
    this.queryNationList()
  },
  methods: {
    handleDel () {
      this.$notify({
        title: '失败',
        message: '不可以删除',
        type: 'warning'
      })
    },
    handleEditNations (row) {
      this.dialogNations = true
      this.formNations = row
      this.formData.countryCodeList = row.nations
    },
    handleEditIcons (row) {
      this.dialogIcons = true
      this.formIcons = row
    },
    handleComplete (data) {
      this.dataList.forEach((row, index) => {
        if (row.id === data.id) {
          row.icons = data.icons
          row.iconsArr = row.icons
          this.$set(this.dataList, index, row)
        }
      })
      this.dialogIcons = false
    },
    handleNationsComplete (data) {
      console.log(this.formData.countryCodeList)
      var nations = []
      var nationsZhs = []
      this.formData.countryCodeList.forEach(nationsCode1 => {
        nations.push(nationsCode1)
      })
      nations.forEach(nationsCode2 => {
        this.nationList.forEach(item => {
          if (nationsCode2 === item.nationsCode) {
            nationsZhs.push(item.nationsZh)
          }
        })
      })
      this.dataList.forEach((row, index) => {
        if (row.id === data.id) {
          data.nations = nations
          data.nationsZh = nationsZhs
          this.$set(this.dataList, index, data)
        }
      })
      this.dialogNations = false
    },
    handleSuccess (res) {
      this.isLoading = false
      if (res.code === 200) {
        console.log(res.data.url)
        this.formIcons.icons.push(res.data.url)
      } else {
        this.$message.error(res.msg)
      }
    },
    handleRemove (value) {
      this.formIcons.icons.splice(this.formIcons.icons.indexOf(value), 1)
    },
    filterMethod (keyword) {
      this.filterNationList = keyword ? this.nationList.filter(item => {
        return item.nationsCode.toLowerCase().startsWith(keyword.toLowerCase())
      }) : this.nationList
    },
    changeSelectState (listKey, fullList, scope) {
      scope[listKey].length === fullList.length
        ? scope[listKey] = []
        : scope[listKey] = fullList
    },
    selectButtonLabel (currentList, fullList) {
      return currentList.length === fullList.length ? '取消全选' : '全选'
    },
    queryPayList () {
      return queryPayList({}).then(res => {
        if (res.code === 200) {
          this.dataList = res.data.list
          this.dataList.forEach(row => {
            if (row.icons !== '') {
              row.icons = JSON.parse(row.icons)
              if (Array.isArray(row.icons)) {
                row.iconsArr = row.icons
              } else {
                row.icons = []
                row.iconsArr = []
              }
            }
            if (row.nations !== '') {
              row.nations = JSON.parse(row.nations)
              var nationsZhs = []
              if (Array.isArray(row.nations) && row.nations.length > 0) {
                row.nations.forEach(nationsCode => {
                  this.nationList.forEach(item2 => {
                    if (nationsCode === item2.nationsCode) {
                      nationsZhs.push(item2.nationsZh)
                    }
                  })
                })
                row.nationsZh = nationsZhs
              } else {
                row.nations = []
              }
            }
            this.nationsClass[row.id] = 'wrap'
          })
        }
      })
    },
    queryNationList () {
      return queryNationList({}).then(res => {
        if (res.code === 200) {
          this.queryPayList()
          const sortNationList = res.data.list
          this.nationList = sortNationList
          this.filterNationList = sortNationList
          this.formData.countryCodeList = []
          sortNationList.forEach(item => {
            this.nationsCode.push(item.nationsCode)
          })
        }
      })
    },
    handleConfirm () {
      this.dataList.forEach(row => {
        if (typeof row.icons !== 'string') {
          row.icons = JSON.stringify(row.icons)
          row.nations = JSON.stringify(row.nations)
        }
      })
      updatePayList({ list: this.dataList }).then(res => {
        if (res.code === 200) {
          if (res.data.success === true) {
            this.queryPayList()
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
          } else {
            this.$notify({
              title: '失败',
              message: '不可以删除',
              type: 'warning'
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pre-header {
  height: 46px;
.select-all {
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  font-size: 14px;
  margin: 10px 0;
  padding: 10px 20px;
  cursor: pointer;
  background: #F5F7FA;
  box-sizing: border-box;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
&::before {
   content: '';
   display: block;
   height: 10px;
   background: white;
   position: absolute;
   top: -10px;
   left: 0;
   width: 100%;
 }
&:hover {
   color: cornflowerblue;
 }
}
}
</style>
